import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { MatTableDataSource } from '@angular/material/table';
import { formatDate } from '@app/helpers/date';
import { PdsAbonnement, VisuDetails } from '@app/models/data';
import { TableColumn } from '@app/models/tableColumn';
import { LabelValue } from '@app/models/ui';
import { DetailsStore } from '@app/store/details.store';
import { get, last } from 'lodash';
import { Report } from 'notiflix';
import { DetailsService } from '@app/services/details.service';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { MatDialog } from '@angular/material/dialog';
import { CompteurPdsAboComponent } from '@app/components/modals/compteur-pds-abo/compteur-pds-abo.component';

@Component({
  selector: 'app-synthese',
  templateUrl: './synthese.component.html',
  styleUrls: ['./synthese.component.scss'],
})
export class SyntheseComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  details: VisuDetails | null;
  situation_actuelle: LabelValue[] = [];
  history: (pdsAbo: PdsAbonnement) => LabelValue[];

  historyColumns: TableColumn[] = [
    {
      getValue: (element: PdsAbonnement) =>
        element.deleted ? 'Supprimé' : 'Actif',
      type: 'tag',
      field: 'deleted',
      label: 'Supprimé',
    },
    {
      getValue: (element: PdsAbonnement) => element.pds.numero_pds,
      field: 'numero_pds',
      label: 'N° PDS',
    },
    {
      getValue: (element: PdsAbonnement) =>
        element.abonnement.numero_abonnement,
      field: 'numero_abonnement',
      label: 'N° Abonnement',
    },
    {
      getValue: (element: PdsAbonnement) => element.compteur.numero_compteur,
      field: 'numero_compteur',
      label: 'N° Compteur',
      type: 'actionLinkBtn',
      action: (row: PdsAbonnement) => {
        this.dialog.open(CompteurPdsAboComponent, {
          data: {
            numero_compteur: row.compteur.numero_compteur,
          },
        });
      },
    },
    {
      getValue: (element: PdsAbonnement) =>
        formatDate(element.compteur.date_pose),
      field: 'date_pose',
      label: 'Date de pose compteur',
    },
    {
      getValue: (element: PdsAbonnement) =>
        formatDate(element.compteur.date_depose),
      field: 'date_depose',
      label: 'Date de dépose compteur',
    },
    {
      getValue: (element: PdsAbonnement) => element.motif_depose,
      field: 'motif_depose',
      label: 'Motif de dépose',
    },
    {
      getValue: (element: PdsAbonnement) => formatDate(element.created_at),
      field: 'created_at',
      label: 'Created At',
    },
    {
      getValue: (element: PdsAbonnement) =>
        formatDate(element.compteur.date_depose),
      field: 'delete',
      label: 'Désactiver/Activer',
      type: 'actionToggle',
      toggleField: 'deleted',
      toggleInverse: true,
      width: 20,
      icon: 'delete',
      actionToggle: (event, row) =>
        this.toggle(row, event.checked ?? row.deleted),
    },
  ];
  historyDataSource = new MatTableDataSource<any>([]);

  latestPDS: PdsAbonnement | null;

  constructor(
    private detailsStore: DetailsStore,
    private detailsService: DetailsService,
    public dialog: MatDialog
  ) {
    this.detailsStore.details$.subscribe((details) => {
      this.details = details;
      if (this.details) {
        if (this.details?.pds_abonnements?.length) {
          this.latestPDS = last(this.details?.pds_abonnements) || null;
          this.historyDataSource.data = this.details.pds_abonnements;
        }
        this.situation_actuelle = [
          {
            label: 'N° du compteur',
            value: () => {
              return this.details?.vm_patrimoine?.numero_compteur || '';
            },
          },
          { label: 'Statut (posé, déposé)', value: () => 'Vide' },
          {
            label: 'N° du dernier PDS',
            value: () => {
              return this.details?.vm_patrimoine.numero_pds || '';
            },
          },
          {
            label: 'Date de pose',
            value: () => {
              if (!this.latestPDS?.compteur?.date_pose) return '';
              return formatDate(this.latestPDS?.compteur?.date_pose) || '';
            },
          },
          {
            label: 'Index de pose',
            value: () =>
              this.latestPDS ? get(this.latestPDS, 'index_pose', '') || '' : '',
          },
          {
            label: 'accessibilité du compteur',
            value: () => {
              return this.latestPDS
                ? get(this.latestPDS, 'abonnement.accessibilite', '') || ''
                : '';
            },
          },
        ];
      }
    });
  }

  ngOnInit(): void {}

  toggle(row: PdsAbonnement, value: boolean) {
    this.detailsService
      .togglePdsAbonnement(row.id, value, 'MCO')
      .subscribe((res) => {
        row.deleted = res.deleted;
        Notify.success(res.deleted ? 'Lien désactivé' : 'Lien activé');
      });
  }
}
