<div
  class="filter-toggle"
  (click)="toggle()"
  [@showHideTrigger]="!isOpen ? 'show' : 'hide'"
>
  <mat-icon>filter_list</mat-icon>
</div>
<div
  class="filters mat-elevation-z24"
  [@openCloseFilter]="isOpen ? 'open' : 'closed'"
>
  <div class="heading">
    <div class="heading-tab-title">FILTRE</div>
    <mat-icon class="sidebar-close" (click)="toggle()">arrow_back</mat-icon>
  </div>
  <form [formGroup]="simpleFormGroup" (keyup.enter)="submitFilters()">
    <div class="filter-body">
      <!-- Filters -->
      <mat-accordion [multi]="false">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title> RECHERCHE SIMPLE </mat-panel-title>
          </mat-expansion-panel-header>

          <form [formGroup]="simpleFormGroup">
            <mat-form-field>
              <mat-label>N° abonné</mat-label>
              <input
                matInput
                placeholder=""
                formControlName="numero_abonnement"
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>N° PDS</mat-label>
              <input matInput placeholder="" formControlName="numero_pds" />
            </mat-form-field>

            <mat-form-field disabled>
              <mat-label>N° compteur</mat-label>
              <input
                matInput
                placeholder=""
                formControlName="numero_compteur"
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>N° module</mat-label>
              <input matInput placeholder="" formControlName="numero_module" />
            </mat-form-field>
          </form>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title> RECHERCHE AVANCÉE </mat-panel-title>
          </mat-expansion-panel-header>
          <!-- REGION FILTER -->
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="filter-footer">
      <!--      <div [hidden]="true" class="filter-footer-warning">-->
      <!--        <span class="filter-footer-warning-text">Vous devez choisir au moins un contrat</span>-->
      <!--      </div>-->
      <button mat-stroked-button color="primary" (click)="emptyFilters()">
        REINITIALISER LES FILTRES
      </button>
      <div [matTooltip]="getAppliquerToolTip()">
        <button
          [disabled]="!isSimpleFilterSelected()"
          mat-raised-button
          color="primary"
          (click)="submitFilters()"
          type="submit"
        >
          APPLIQUER
        </button>
      </div>
    </div>
  </form>
</div>
