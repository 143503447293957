import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {handleHttpError} from '@app/helpers/errors';
import {PdsAboCompteur, PdsAbonnement, VisuDetails} from '@app/models/data';
import {LoginStore} from '@app/store/login.store';
import {environment} from '@env/environment';
import {catchError} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DetailsService {
  private VISU_PAT_URL = `${environment.API_URL}/v1/visu-patrimoine/`;
  private token: string | null;

  constructor(private httpClient: HttpClient, private loginStore: LoginStore) {
    this.loginStore.token$.subscribe((token) => {
      if (token) {
        this.token = token;
      }
    });
  }

  getDetails(filters: any) {
    const headers = {
      Authorization: `Bearer ${this.token}`,
    };

    return this.httpClient
      .post<VisuDetails>(
        this.VISU_PAT_URL,
        {...filters},
        {
          headers,
        }
      )
      .pipe(catchError(handleHttpError));
  }

  getPdsAboCompteur(numero_compteur: string) {
    const headers = {
      Authorization: `Bearer ${this.token}`,
    };

    return this.httpClient
      .get<PdsAboCompteur[]>(
        `${this.VISU_PAT_URL}pds-abonnement/pds_abo_by_compteur/`,
        {
          headers,
          params: new HttpParams().set('numero_compteur', numero_compteur),
        }
      )
      .pipe(catchError(handleHttpError));
  }

  togglePdsAbonnement(id: number, active: boolean, motif?: string) {
    const headers = {
      Authorization: `Bearer ${this.token}`,
    };

    return this.httpClient
      .post<PdsAbonnement>(
        `${this.VISU_PAT_URL}pds-abonnement/toggle/`,
        {id, active, motif_depose: motif || null},
        {
          headers,
        }
      )
      .pipe(catchError(handleHttpError));
  }
}

