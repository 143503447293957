import { Injectable } from '@angular/core';
import { PdsAboCompteur, PdsAbonnement, VisuDetails } from '@app/models/data';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DetailsStore {
  private details = new BehaviorSubject<VisuDetails | null>(null);
  details$ = this.details.asObservable();
  private pdsAboCompteur = new BehaviorSubject<PdsAboCompteur[]>([]);
  pdsAboCompteur$ = this.pdsAboCompteur.asObservable();
  private loading = new BehaviorSubject(false);
  loading$ = this.loading.asObservable();
  constructor() {}
  setPdsAboCompteur(pdsAboCompteur: PdsAboCompteur[]) {
    this.pdsAboCompteur.next(pdsAboCompteur);
  }
  setDetails(details: any) {
    this.details.next(details);
  }
  setLoading(loading: any) {
    this.loading.next(loading);
  }
}
