import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { LoginStore } from '@app/store/login.store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OfflineGuard implements CanActivate {
  constructor(private router: Router, private loginStore: LoginStore) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise((resolve) => {
      this.loginStore.user$.subscribe((user: any) => {
        if (user && user.email) {
          resolve(false);
          this.router.navigateByUrl('/');
        } else {
          resolve(true);
        }
      });
    });
  }
}
