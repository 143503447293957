import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

export const animations = [
  trigger('openCloseFilter', [
    state(
      'open',
      style({
        left: 0,
        width: '260px',
        opacity: 1,
      })
    ),
    state(
      'closed',
      style({
        left: '-280px',
        width: '0',
        opacity: 0,
      })
    ),
    transition('* => closed', [animate('0.2s')]),
    transition('* => open', [animate('0.2s 0.1s')]),
  ]),
  trigger('showHideTrigger', [
    state(
      'show',
      style({
        left: 0,
        display: 'flex',
      })
    ),
    state(
      'hide',
      style({
        left: '-60px',
        display: 'none',
      })
    ),
    transition('show => hide', [animate('0.2s')]),
    transition('hide => show', [animate('0.1s 0.1s')]),
  ]),
];
