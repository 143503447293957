<div class="container">
  <div
    class="loading-layout"
    *ngIf="dataLoading"
    style="margin-bottom: -20px; margin-top: 20px"
  >
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div [ngClass]="{ loading: dataLoading }" class="table-wrapper">
    <table mat-table [dataSource]="dataSource">
      <ng-container [matColumnDef]="col.field" *ngFor="let col of TableColumns">
        <div *ngIf="!col.type">
          <th
            mat-header-cell
            *matHeaderCellDef
            [ngStyle]="{ 'min-width.px': col.width ? col.width : '' }"
          >
            {{ col.label }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ 'center-text': true }"
            [ngStyle]="{ 'min-width.px': col.width ? col.width : '' }"
            [matTooltip]="get(element, col.tooltip || '', '')"
            [matTooltipDisabled]="
              get(element, col.tooltip || '', 'empty') === 'empty'
            "
          >
            {{ col.getValue ? col.getValue(element) : '' }}
          </td>
        </div>
        <div *ngIf="col.type === 'actionLinkBtn'">
          <th
            mat-header-cell
            *matHeaderCellDef
            [ngStyle]="{ 'min-width.px': col.width ? col.width : '' }"
          >
            {{ col.label }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngClass]="{ 'center-text': true }"
            [ngStyle]="{ 'min-width.px': col.width ? col.width : '' }"
            [matTooltip]="get(element, col.tooltip || '', '')"
            [matTooltipDisabled]="
              get(element, col.tooltip || '', 'empty') === 'empty'
            "
          >
            <button
              mat-button
              color="primary"
              (click)="col.action ? col.action(element) : ''"
            >
              {{ col.getValue ? col.getValue(element) : '' }}
            </button>
          </td>
        </div>
        <div *ngIf="col.type === 'actions'">
          <th
            mat-header-cell
            *matHeaderCellDef
            [ngStyle]="{ 'min-width.px': col.width ? col.width : '' }"
            class="actions-cell"
          >
            {{ col.label }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngStyle]="{ 'min-width.px': col.width ? col.width : '' }"
            [matTooltip]="get(element, col.tooltip || '', '')"
            [matTooltipDisabled]="
              get(element, col.tooltip || '', 'empty') === 'empty'
            "
            class="actions-cell"
          >
            <button
              mat-icon-button
              color="primary"
              (click)="col.action ? col.action(element) : ''"
            >
              <mat-icon>{{ col.icon || 'home' }}</mat-icon>
            </button>
          </td>
        </div>
        <div *ngIf="col.type === 'tag'">
          <th
            mat-header-cell
            *matHeaderCellDef
            [ngStyle]="{ 'min-width.px': col.width ? col.width : '' }"
            class="actions-cell"
          >
            {{ col.label }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngStyle]="{ 'min-width.px': col.width ? col.width : '' }"
            [matTooltip]="get(element, col.tooltip || '', '')"
            [matTooltipDisabled]="
              get(element, col.tooltip || '', 'empty') === 'empty'
            "
            class="actions-cell"
          >
            <mat-chip-list>
              <mat-chip
                color="danger"
                style="margin: auto; display: block"
                [ngClass]="{
                  'red-tag': element?.deleted,
                  'green-tag': !element?.deleted
                }"
              >
                {{ col.getValue ? col.getValue(element) : '' }}</mat-chip
              ></mat-chip-list
            >
          </td>
        </div>
        <div
          *ngIf="
            col.type === 'actionToggle' && col.toggleField && col.actionToggle
          "
        >
          <th
            mat-header-cell
            *matHeaderCellDef
            [ngStyle]="{ 'min-width.px': col.width ? col.width : '' }"
            class="actions-cell"
          >
            {{ col.label }}
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [ngStyle]="{ 'min-width.px': col.width ? col.width : '' }"
            [matTooltip]="get(element, col.tooltip || '', '')"
            [matTooltipDisabled]="
              get(element, col.tooltip || '', 'empty') === 'empty'
            "
            class="actions-cell"
          >
            <ng-container
              *ngIf="element && element.hasOwnProperty(col.toggleField)"
            >
              <mat-slide-toggle
                [disableRipple]="true"
                [checked]="
                  col.toggleInverse
                    ? !element[col.toggleField]
                    : element[col.toggleField]
                "
                color="primary"
                (change)="col.actionToggle($event, element)"
              ></mat-slide-toggle>
            </ng-container>
          </td>
        </div>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [pageSize]="20"
      [pageSizeOptions]="[20, 50, 100]"
      showFirstLastButtons
    ></mat-paginator>
  </div>
</div>
