<div class="hyper-vision-header">
  <!--Mobile Menu-->
  <!--  <button mat-icon-button class="mobile-menu-icon">-->
  <!--    <mat-icon>menu</mat-icon>-->
  <!--  </button>-->
  <!--Desktop/Tablet Brand-->
  <div class="brand">
    <img
      src="assets/hubgrade/essentiel-w.svg"
      alt="{{ app_name }} par Veolia"
    />
    <div class="separator"></div>
    <div class="current-app">{{ app_name }}</div>
  </div>

  <!--Mobile Brand-->
  <div class="mobile-brand">
    <img
      src="assets/hubgrade/essentiel-w.svg"
      alt="{{ app_name }} par Veolia"
    />
  </div>
  <div class="right-side" *ngIf="user && user.email">
    <button
      mat-icon-button
      class="user-avatar"
      (click)="popupToggle('avatar')"
      click-bypass
    >
      <img
        onerror="this.src='/assets/img/blank-profile-picture.png'"
        class="user-img"
        [src]="user.picture"
        alt="{{ user.given_name }} {{ user.family_name }}"
      />
    </button>
    <!--
      Avatar POPUP
    -->
    <div
      class="avatar-popup short"
      [hidden]="!isPopup('avatar')"
      style="--triangle-right: 53px"
      click-bypass
    >
      <div class="left-side">
        <img
          onerror="this.src='/assets/img/blank-profile-picture.png'"
          class="user-img"
          [src]="user.picture"
          alt="{{ user.given_name }} {{ user.family_name }}"
        />
      </div>
      <div class="right-side">
        <div class="full-name">{{ user.given_name }} {{ user.family_name }}</div>
        <div class="email">{{ user.email }}</div>
      </div>
    </div>
    <button
      mat-icon-button
      class="menu-icon"
      (click)="popupToggle('menu')"
      click-bypass
    >
      <mat-icon>more_vert</mat-icon>
    </button>

    <!--
      Menu POPUP
    -->
    <div
      class="menu-popup"
      [hidden]="!isPopup('menu')"
      style="--triangle-right: 4px"
      click-bypass
    >
      <mat-list>
        <mat-nav-list>
          <a mat-list-item target="_blank" (click)="closePopup()">
            <h3 matLine>Fiche mémo et guide utilisateur</h3>
          </a>
          <mat-list-item (click)="logout()">
            <h3 matLine>Déconnexion</h3>
          </mat-list-item>
        </mat-nav-list>
      </mat-list>
    </div>
  </div>
</div>
