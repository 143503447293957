import { Component, OnInit, Renderer2 } from '@angular/core';
import { environment } from '@env/environment';
import { loadScript } from './helpers/scripts';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'visu-patrimoine';
  constructor() {}

  ngOnInit(): void {
    
  }
  
}
