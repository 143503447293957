import { Component, OnInit } from '@angular/core';
import { DetailsService } from '@app/services/details.service';
import { DetailsStore } from '@app/store/details.store';
import { Report } from 'notiflix/build/notiflix-report-aio';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    private detailsService: DetailsService,
    private detailsStore: DetailsStore
  ) {}

  ngOnInit(): void {}

  filtersChanged(filters: any) {
    if (Object.values(filters).some((val) => !!val)) {
      this.detailsStore.setLoading(true);
      this.detailsService.getDetails(filters).subscribe({
        next: (res: any) => {
          this.detailsStore.setDetails(res);
          this.detailsStore.setLoading(false);
        },
        error: () => {
          this.detailsStore.setLoading(false);
          Report.failure(
            'Compteur introuvable',
            'Compteur introuvable ou hors de votre périmètre contractuelle',
            'OK'
          );
        },
      });
    } else {
      this.detailsStore.setDetails(null);
    }
  }
}
