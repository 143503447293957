import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getWithExpiry, setWithExpiry } from '@app/helpers/localStorage';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginStore {
  private authTTL = 10; // 10 hours

  private user = new BehaviorSubject(null);
  user$ = this.user.asObservable();
  private token = new BehaviorSubject('');
  token$ = this.token.asObservable();

  constructor(private router: Router) {
    const token = getWithExpiry('token');
    if (token) {
      this.token.next(token);
    }
    const user = getWithExpiry('user');
    if (user) {
      this.user.next(user);
    }
  }

  setUser(user: any) {
    this.user.next(user);
    setWithExpiry('user', user, this.authTTL);
  }

  setToken(token: any) {
    this.token.next(token);
    setWithExpiry('token', token, this.authTTL);
  }

  signOut() {
    localStorage.clear();
    this.user.next(null);
    this.token.next('');
    this.router.navigateByUrl('/login');
  }
}
