import { Component, HostListener, OnInit } from '@angular/core';
import { LoginStore } from '@app/store/login.store';
import { environment } from 'src/environments/environment';
import Tether from 'tether';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  popup = '';
  app_name = '';
  brand_name = '';
  user: any = null;

  constructor(private loginStore: LoginStore) {
    this.app_name = environment.APP_NAME;
    this.brand_name = environment.BRAND_NAME;
    this.loginStore.user$.subscribe((user) => {
      if (user) {
        this.user = user;
      } else {
        this.user = null;
      }
    });
  }

  ngOnInit(): void {}

  logout() {
    this.closePopup();
    setTimeout(() => {
      this.loginStore.signOut();
    });
  }

  newTether(element: string) {
    const tether = new Tether({
      element: `.${element}-popup`,
      target: '.hyper-vision-header',
      attachment: 'top right',
      targetAttachment: 'bottom right',
      offset: '2px 15px',
    });
    setTimeout(() => {
      tether.position();
    });
    this.popup = element;
  }

  popupToggle(element: string) {
    if (!this.popup || this.popup !== element) {
      this.newTether(element);
    } else {
      this.popup = '';
    }
  }

  isPopup(element: string) {
    return this.popup === element;
  }

  closePopup() {
    if (this.popup !== '') {
      this.popup = '';
    }
  }

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    if (this.popup) {
      const targetElement = event.target as HTMLElement;
      if (!targetElement.closest('[click-bypass]')) {
        this.popup = '';
      }
    }
  }
}
