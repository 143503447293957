<div class="container">
  <mat-tab-group id="details-content">
    <mat-tab label="Synthèse compteur" [disabled]="!details">
      <app-synthese *ngIf="details"></app-synthese>
    </mat-tab>
    <mat-tab label="Equipements radio" [disabled]="!details">
      <app-equipements *ngIf="details"></app-equipements>
    </mat-tab>
    <mat-tab label="PDS" [disabled]="!details">
      <app-pds *ngIf="details"></app-pds>
    </mat-tab>
  </mat-tab-group>
</div>
