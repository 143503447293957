import {SelectionModel} from '@angular/cdk/collections';
import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  Type,
  ViewChild,
} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {TableColumn} from '@app/models/tableColumn';
import {get} from 'lodash';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input() TableColumns: TableColumn[];
  @Input() dataSource: MatTableDataSource<any>;

  dataLoading = false;
  displayedColumns: string[];
  get = get;
  toggle = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.TableColumns) {
      this.displayedColumns = this.TableColumns.map((x) => x.field);
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
}
