export function loadScript(
  id: string,
  src: string,
  onload: any,
  parentElement: any = null
) {
  if (typeof document !== 'undefined' && !document.getElementById(id)) {
    let signInJS = document.createElement('script');
    signInJS.src = src;
    signInJS.async = true;
    signInJS.defer = true;
    signInJS.onload = onload;
    if (!parentElement) {
      parentElement = document.head;
    }
    parentElement.appendChild(signInJS);
  }
}
export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
