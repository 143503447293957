import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { loadScript, parseJwt } from '@app/helpers/scripts';
import { LoginStore } from '@app/store/login.store';
import { environment } from '@env/environment';
declare let google: any;
document
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  gsiSetup = false;
  user: any = null;
  constructor(private loginStore: LoginStore, private router: Router) {
    this.loginStore.user$.subscribe((user) => {
      this.user = user || null;
    });
  }

  ngOnInit(): void {
    if (!this.gsiSetup) {
      loadScript('GOOGLE', 'https://accounts.google.com/gsi/client', () => {
        google.accounts.id.initialize({
          client_id: environment.CLIENT_ID,
          callback: (res: any) => this.login(res, document),
          locale: 'fr_FR',
        });
        google.accounts.id.renderButton(
          document.getElementById('googleLoginButton'),
          {
            theme: 'filled_blue',
            size: 'large',
            locale: 'fr_FR',
            shape: 'pill',
          }
        );
        google.accounts.id.prompt();
        this.gsiSetup = true;
      });
    }
  }
  login(res: any, document: any) {
    if (res.credential) {
      const user = parseJwt(res.credential);
      this.loginStore.setToken(res.credential);
      this.loginStore.setUser(user);

      // FIXME:: DO NOT remove these two lines, unless if you know why they're here
      document.body.click();
      document.body.click();

      this.router.navigateByUrl('/');
    }
  }
}
