<div class="container">
  <div class="card">
    <div class="title">Situation actuelle du compteur</div>
    <div class="items">
      <div class="item" *ngFor="let item of situation_actuelle">
        <div class="label">{{ item.label }} :</div>
        <div class="value">{{ item.value() || '-' }}</div>
      </div>
    </div>
  </div>
  <div class="card dense full-width">
    <div class="title">Historique des évolutions du compteur</div>
    <div class="table-wrapper">
      <app-table
        class="table"
        [dataSource]="historyDataSource"
        [TableColumns]="historyColumns"
      ></app-table>
    </div>
  </div>
</div>
