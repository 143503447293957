// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  CLIENT_ID:
    '251853433236-f6pca41aejdfdup9da5pkilua9u9qji2.apps.googleusercontent.com',
  BRAND_NAME: 'TELEO',
  APP_NAME: 'VisuPatrimoine',
  API_URL: 'https://rec-backend-teleo-pat.hp.m-ve.com/api',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
