import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { formatDate } from '@app/helpers/date';
import { PdsAboCompteur } from '@app/models/data';
import { TableColumn } from '@app/models/tableColumn';
import { DetailsService } from '@app/services/details.service';

interface DialogData {
  numero_compteur: string;
}
@Component({
  selector: 'app-compteur-pds-abo',
  templateUrl: './compteur-pds-abo.component.html',
  styleUrls: ['./compteur-pds-abo.component.scss'],
})
export class CompteurPdsAboComponent implements OnInit {
  pdsAbo = new MatTableDataSource<PdsAboCompteur>([]);
  pdsAboColumns: TableColumn[] = [
    {
      getValue: (element: PdsAboCompteur) => element.numero_pds,
      field: 'numero_pds',
      label: 'N° PDS',
    },
    {
      getValue: (element: PdsAboCompteur) => element.numero_abonnement,
      field: 'numero_abonnement',
      label: 'N° Abonnement',
    },
    {
      getValue: (element: PdsAboCompteur) => element.numero_compteur,
      field: 'numero_compteur',
      label: 'N° Compteur',
    },
    {
      getValue: (element: PdsAboCompteur) => formatDate(element.date_pose),
      field: 'date_pose',
      label: 'Date de pose compteur',
    },
    {
      getValue: (element: PdsAboCompteur) => formatDate(element.date_depose),
      field: 'date_depose',
      label: 'Date de dépose compteur',
    },
    {
      getValue: (element: PdsAboCompteur) => element.motif_depose,
      field: 'motif_depose',
      label: 'Motif de dépose',
    },
  ];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private detailsService: DetailsService
  ) {
    this.detailsService
      .getPdsAboCompteur(data.numero_compteur)
      .subscribe((pdsAbo) => {
        this.pdsAbo.data = pdsAbo;
      });
  }

  ngOnInit(): void {}
}
