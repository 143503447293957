import {Component, Input, OnInit} from '@angular/core';
import {DetailsStore} from '@app/store/details.store';
import {Block} from 'notiflix/build/notiflix-block-aio';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
  details: any = null;
  loading = false;

  constructor(private detailsStore: DetailsStore) {
    this.detailsStore.details$.subscribe((details) => (this.details = details));
    this.detailsStore.loading$.subscribe((loading) => {
      if (loading) {
        Block.hourglass('#details-content', 'Veuillez patienter...');
      } else {
        Block.remove('#details-content');
      }
      this.loading = loading;
    });
  }

  ngOnInit(): void {
  }
}
