import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { first, single, take } from 'rxjs';
import { animations } from './filters.animation';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
  animations,
})
export class FiltersComponent implements OnInit {
  @Output() filterMode: EventEmitter<string> = new EventEmitter<string>();
  @Output() filtersChanged: EventEmitter<any> = new EventEmitter<any>();

  public simpleFormGroup: FormGroup;

  public isContractSelected = false;
  isOpen = true;
  fieldsDisabled = false;
  constructor(private fb: FormBuilder) {
    this.simpleFormGroup = this.fb.group({
      numero_abonnement: [''],
      numero_pds: [''],
      numero_compteur: [''],
      numero_module: [''],
    });
  }

  ngOnInit(): void {
    this.simpleFormGroup.valueChanges.pipe().subscribe((val) => {
      const filledField = Object.keys(val).find((key) => !!val[key]) || '';
      if (filledField && !this.fieldsDisabled) {
        this.disableAllFields(filledField);
        this.fieldsDisabled = true;
      } else if (!filledField && this.fieldsDisabled) {
        this.enableAllFields();
        this.fieldsDisabled = false;
      }
    });
  }

  disableAllFields(exclude: string) {
    Object.keys(this.simpleFormGroup.controls)
      .filter((key) => key !== exclude)
      .forEach((key) => {
        this.simpleFormGroup.controls[key].disable({ emitEvent: false });
      });
  }

  enableAllFields() {
    Object.keys(this.simpleFormGroup.controls).forEach((key) => {
      this.simpleFormGroup.controls[key].enable({ emitEvent: false });
    });
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  getAppliquerToolTip() {
    if (!this.isSimpleFilterSelected()) {
      return 'Vous devez saisir un filtre pour effectuer une recherche';
    } else return '';
  }

  isSimpleFilterSelected() {
    return Object.values(this.simpleFormGroup.value).some((val) => !!val);
  }

  submitFilters() {
    if (this.isSimpleFilterSelected()) {
      this.filtersChanged.emit(this.simpleFormGroup.value);
    }
  }
  emptyFilters() {
    this.simpleFormGroup.reset();
    this.filtersChanged.emit(this.simpleFormGroup.value);
  }
}
